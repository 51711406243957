// Import necessary modules and assets
import newPassword from "./helpers/new-password.js";

/**
 * Auth class for authentication related JS functionality.
 */
class Auth {
    constructor() {
        this._init();
    }

    /**
     * Initialize the Auth class
     */
    _init() {
        console.log('[Auth] Initializing...');

        this._initPasswordToggle();
        this._bindRecaptchaFormSubmission();
        this._initDVSSelector();
        newPassword(document.getElementById('container-auth-force-new-password'));

        console.log('[Auth] Initialized!');
    }

    /**
     * Initialize the password toggle functionality.
     * Adds click event listeners to elements with the class 'toggle-password'.
     * Toggles the input type between 'text' and 'password' and updates the icon accordingly.
     *
     * @protected
     */
    _initPasswordToggle() {
        let togglePassword = document.querySelectorAll('.toggle-password');

        togglePassword.forEach(function(toggle) {
            toggle.addEventListener('click', function() {
                let inputPassword = this.parentElement.querySelector('input');

                // Toggle the input type between 'text' and 'password'
                inputPassword.type = inputPassword.type === 'text' ? 'password' : 'text';

                // If the icon element does not exist, return
                if(!this.querySelector('.icon')) {
                    return;
                }

                // Update the icon to reflect the current state
                this.querySelector('.icon').classList.toggle('fa-eye');
                this.querySelector('.icon').classList.toggle('fa-eye-slash');
            });
        });
    }

    /**
     * Bind form submission to execute reCAPTCHA.
     * Adds submit event listeners to forms with the data attribute 'data-recaptcha="true"'.
     * Prevents default form submission and executes reCAPTCHA before submitting the form.
     *
     * @protected
     */
    _bindRecaptchaFormSubmission() {
        let recaptchaSiteKey = config.settings.recaptcha_site_key;

        if (!recaptchaSiteKey.length) {
            return;
        }

        grecaptcha.ready(function() {
            $('.g-recaptcha').each(function () {
                let recaptchaResponse = $(this);

                grecaptcha.execute(recaptchaSiteKey, {
                    action: 'validate_captcha'
                })
                    .then(function (token) {
                        recaptchaResponse.val(token);
                    });
            });
        });
    }

    /**
     * Initialize the DVS selector functionality.
     * Adds a click event listener to the element with the id 'select-dvs'.
     * Submits the inner form when the element is clicked.
     *
     * @protected
     */
    _initDVSSelector() {
        const dvsSelector = document.getElementById('dvs-selector');

        if(!dvsSelector) {
            return;
        }

        dvsSelector.querySelectorAll('.select-dvs').forEach(function(selectDvs) {
            selectDvs.addEventListener('click', function(e) {
                // Get the corresponding form element within the select-dvs element and submit it
                const form = this.querySelector('form');

                if (form) {
                    form.submit();
                } else {
                    console.error("[Auth] No 'Form' element found within the selected 'select-dvs' element.");
                }
            });
        });
    }
}

window.addEventListener('load', () => {
    window.auth = new Auth();
});
